import { LineChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import React from 'react';

const RenderLineChart = ({isStatsTypeNet, data}) => {
  const lineColors = {'max_pnl': 'blue', 'min_pnl': 'red', 'avg_pnl': 'black','equity_curve': 'black'}

  return (
    <ResponsiveContainer width="99%" aspect={3}>
      <LineChart width={400} height={400} >
      {
        data &&
        Object.keys(data).map((key, index) => (
          <React.Fragment key={key}>
            <Line xAxisId={key} name={key} type="monotone" dataKey={isStatsTypeNet? "net_pnl_percent":"gross_pnl_percent"} data={data[key]} stroke={lineColors[key]}/>
            <XAxis hide={index > 0} xAxisId={key} dataKey="name" domain={['auto', 'auto']} />
            <Legend iconType="plainline" />
          </React.Fragment>
        ))
      }
        <CartesianGrid stroke="#ccc" />
      <YAxis />
    </LineChart>
    </ResponsiveContainer>
    
  )
  
  }

export default RenderLineChart;